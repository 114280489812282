.preview-table {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.table-container {
  max-width: 100%;
  max-height: 400px;
  overflow: auto;
  border: 1px solid #ddd;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid #ddd;
}

td {
  padding: 8px;
  white-space: nowrap; /* Prevent wrapping of text */
}

input {
  width: 100%;
  box-sizing: border-box;
}

.preview-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}


.chat-message {
  max-width: 80%;
  margin-bottom: 15px;
  padding: 12px 18px;
  border-radius: 18px;
  font-size: 14px;
  line-height: 1.4;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.chat-message.user {
  align-self: flex-end;
  background-color: #4a90e2;
  color: white;
  border-bottom-right-radius: 4px;
}

.chat-message.bot {
  align-self: flex-start;
  background-color: #ffffff;
  color: #333;
  border-bottom-left-radius: 4px;
}

.chat-message.error {
  background-color: #ff6b6b;
  color: white;
}
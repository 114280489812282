.graphql-logs {
    margin-top: 20px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
  }
  
  .graphql-logs h3 {
    margin-top: 0;
  }
  
  .log-entry {
    margin-bottom: 15px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 5px;
  }
  
  .log-entry h4 {
    margin-top: 0;
    margin-bottom: 5px;
  }
  
  .log-entry pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    background-color: #f5f5f5;
    padding: 5px;
    border-radius: 3px;
  }
.button {
  background-color: #0b2849;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.button:hover {
  background-color: #0b4b99;
}

.button:active {
  background-color: #2a6db2;
}

.button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
.chatbot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #44439e; /* Light blue background */
}

.chatbot {
  width: 100%;
  height: 100%;
  /* border-radius: 20px; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #041d27;
  font-family: 'Arial', sans-serif;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.chatbot-header {
  background-color: #1c1c22;
  color: white;
  padding: 15px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-logo {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.chatbot-header h2 {
  margin: 0;
  font-size: 24px;
}

.chatbot-subheader {
  margin: 0;
  padding: 10px;
  background-color: #2e2e5f;
  color: white;
  text-align: center;
  font-size: 14px;
}

.chatbot-messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: #2b2e33;
}

.chatbot-input {
  display: flex;
  padding: 15px;
  background-color: #06072b;
  border-top: 1px solid #0a3738;
}

.chatbot-input input {
  flex: 1;
  border: none;
  /* background-color: rgb(146, 116, 228); */
  color: #333;
  padding: 12px;
  border-radius: 25px;
  margin-right: 10px;
  font-size: 14px;
}

.chatbot-input input::placeholder {
  color: #041d27;
}

.loading-indicator {
  align-self: center;
  color: #eaedf0;
  margin: 10px 0;
  font-style: italic;
}.chat-message.error {
  background-color: red;
  border-left: 5px solid #f44336;
}

.chat-message.success {
  background-color: #e8f5e9;
  border-left: 5px solid #4caf50;
}